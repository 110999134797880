import { PREFECTURE_SETTING_UNIT } from "./constants"

export const defaultDropOffFeeObject = () => ({
  setting_unit: PREFECTURE_SETTING_UNIT,
  departure_prefecture_id: null,
  departure_shop_id: null,
  destination_prefecture_id: null,
  destination_shop_id: null,
  companies_car_type_ids: [],
  fee: null
})
