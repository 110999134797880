import smoothscroll from "smoothscroll-polyfill"

export default {
  created() {
    smoothscroll.polyfill()
  },

  methods: {
    /**
     * Scrolls passed container to top of passed target
     *
     * @param  scrollConfig.target     Target element to scroll into view
     * @param  scrollConfig.block      Vertical align, optional
     * @param  scrollConfig.inline     Horizontal align, optional
     * @param  scrollConfig.behavior   Scroll behavior, optional
     * @return void
     */
    scrollTo({ target, block = "start", behavior = "smooth", inline = "start" }) {
      const targetElement = document.querySelector(target)
      targetElement !== null && targetElement.scrollIntoView({ block, behavior, inline })
    }
  }
}
